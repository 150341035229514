.etr-dvd-puzzle {
	display:flex;
	flex-direction: horizontal;
}

.etr-dvd-shelf {
	position: absolute;
    z-index: 500;
    width:900px;
    left: 0px;
    right: 0px;
    margin: auto;
}

.etr-dvd-shelf-displacer {
	width: 900px;
	height: 900px;
}

.etr-sortable-dvd{
    position: absolute;
    transform: translate(-10px, 63px);
    width: 750px;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 1000;
}