.EscapeTheRoomPage {
	text-align: left;
}

.etr-title-and-description {
	text-align: left;
	padding: 20px;
}

.etr-title-image-container {
	display: flex;
	flex-direction: row;
}

.etr-interactive-room {
	display: flex;
	justify-content: center;
	padding-bottom: 20px;
	margin: 10px;
	height: 660px;
}

.etr-hangman-wrapper {
	display:flex;
}

.etr-hangman-content {
	padding: 10px;
}

.etr-purple-text {
	background-color: rgba(61,34,141,0.6);
}

.etr-red-text {
	background-color: rgba(244,3,12,0.6);
}

.etr-green-text {
	background-color: rgba(1,147,40,0.6);
}

.etr-blue-text {
	background-color: rgba(4,115,205,0.6);
}

.etr-princeton-hint {
	color: #0645AD;
}

.etr-princeton-hint:hover {
	cursor: pointer;
}

.etr-where-is-tommy {
	text-align: center;
}

.etr-footer {
	text-align: left;
	padding: 20px;
	background-color: #CCCCCC;
	color: black;
}