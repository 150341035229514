.etr-wooden-container {
	position: relative;
}

.etr-timepuzzle-tooltip {
    position: absolute;
    color: #fff;
    background: rgba(0,0,0,0.0);
    transform: translate3d(-50%, -50%, 0) rotate(22.5deg);
    border-radius: 5px;
    pointer-events: none;
    z-index: 1000;
}

.etr-timepuzzle-tooltip2 {
    position: absolute;
    color: #fff;
    background: rgba(1,0,0,0.0);
    transform: translate3d(-50%, -50%, 0) rotate(22.5deg);
    border-radius: 5px;
    pointer-events: none;
    z-index: 2000;
}

.tooltip-rotate{
    transform: translate3d(-50%, -50%, 0) rotate(-90deg);
}