.etr-bookshelf-container {
    display: flex;
}

.etr-book-shelf {
    position: absolute;
    z-index: 500;
    width:900px;
    left: 0px;
    right: 0px;
    margin: auto;
}

.etr-sortable-book{
    position: absolute;
    transform: translate(0px, 150px);
    width: 800px;
    left: 0px;
    right: 0px;
    margin: auto;
    z-index: 1000;
}

.etr-hollow-book {
    position: absolute;
    transform: translate(-100px, 0px);
    z-index: 1000;
    left: 0px;
    right: 0px;
    width: 200px;
    margin: auto;
}

.etr-hollow-book:hover {
    cursor: pointer;
}